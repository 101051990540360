import React from 'react';
import { Container, Typography, Grid } from '@mui/material';
import { ContactCard } from './ContactCard';

import { ContentPageTitle } from 'src/components/ContentPageTitle/ContentPageTitle';

const ContactUsPage = () => {
  const AETimeZone = 'AEST';

  return (
    <>
      <ContentPageTitle currentPageTitle="Contact Us" />

      <Container maxWidth="lg">
        <Typography color="primary.main" variant="h2" sx={{ mt: 3 }}>
          Need CPD assistance?
        </Typography>
        <Grid container spacing={2} rowSpacing={1}>
          <Grid item md={6} sx={{ display: 'flex', width: '100%' }}>
            <ContactCard
              location="New South Wales & Australian Capital Territory - (Sydney)"
              phone="(02) 9886 4700"
              fax="(02) 9886 4790 (fax)"
              email="nswact.cpd@racgp.org.au"
              openingHours={`9:00 am to 5:00 pm ${AETimeZone}`}
              openingDays="Monday to Friday"
            />
          </Grid>
          <Grid item md={6} sx={{ display: 'flex', width: '100%' }}>
            <ContactCard
              location="Queensland"
              phone="(07) 3456 8944"
              fax="(07) 3391 7009 (fax)"
              email="qld.cpd@racgp.org.au"
              openingHours="9:00 am to 5:00 pm AEST"
              openingDays="Monday to Friday"
            />
          </Grid>
          <Grid item md={6} sx={{ display: 'flex', width: '100%' }}>
            <ContactCard
              location="South Australia & Northern Territory - (Adelaide)"
              phone="(08) 8267 8310"
              fax="(08) 8267 8319 (fax)"
              email="sant.cpd@racgp.org.au"
              openingHours="8:00 am to 5:00 pm ACDT"
              openingDays="Monday to Friday"
            />
          </Grid>
          <Grid item md={6} sx={{ display: 'flex', width: '100%' }}>
            <ContactCard
              location="Tasmania"
              phone="(03) 6212 5888"
              fax="(03) 6234 2344 (fax)"
              email="tas.cpd@racgp.org.au"
              openingHours={`9:00 am to 5:00 pm ${AETimeZone}`}
              openingDays="Monday to Friday"
            />
          </Grid>
          <Grid item md={6} sx={{ display: 'flex', width: '100%' }}>
            <ContactCard
              location="Victoria"
              phone="(03) 8699 0396"
              fax="(03) 8699 0560 (fax)"
              email="vic.cpd@racgp.org.au"
              openingHours={`9:00 am to 5:00 pm ${AETimeZone}`}
              openingDays="Monday to Friday"
            />
          </Grid>
          <Grid item md={6} sx={{ display: 'flex', width: '100%' }}>
            <ContactCard
              location="Western Australia"
              phone="(08) 9489 9555"
              fax="(08) 9489 9544 (fax)"
              email="wa.cpd@racgp.org.au"
              openingHours="9:00 am to 5:00 pm AWST"
              openingDays="Monday to Friday"
            />
          </Grid>
          <Grid item md={6} sx={{ display: 'flex', width: '100%' }}>
            <ContactCard
              location="Overseas GPs"
              phone="(+613) 8699 0349"
              fax="(+613) 9696 7511 (fax)"
              email="cpd.national@racgp.org.au"
              openingHours={`9:00 am to 5:00 pm ${AETimeZone}`}
              openingDays="Monday to Friday"
            />
          </Grid>
          <Grid item md={6} sx={{ display: 'flex', width: '100%' }}>
            <ContactCard
              location="General Enquiries"
              phone="1800 716 853"
              phone2="International +61 3 8699 0300"
              email="cpd@racgp.org.au"
              openingHours={`8:00 am to 6:00 pm ${AETimeZone}`}
              openingDays="Monday to Friday"
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export { ContactUsPage };
