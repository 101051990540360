import React, { useRef, useEffect, useContext, forwardRef, MouseEventHandler } from 'react';
import {
  Box,
  Card,
  TextField,
  IconButton,
  Typography,
  Avatar,
  Paper,
  Fade,
  CircularProgress,
  Menu,
  MenuItem
} from '@mui/material';
import { styled } from '@mui/system';
import SendIcon from '@mui/icons-material/Send';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AssistantIcon from '@mui/icons-material/Assistant';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { useOktaAuth } from '@okta/okta-react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

import DOMPurify from 'dompurify';

import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { useMessageHander } from 'src/api/hooks';
import { conversationApi, Feedback, feedbackApi } from 'src/api';
import { XSSAllowTags } from './sanatizeAllowables';

const ChatContainer = styled(Card)(({ theme }) => ({
  // maxWidth: '400px',
  margin: '20px auto',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  borderRadius: '16px',
  height: '600px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column'
}));

const MessageContainer = styled(Box)({
  flex: 1,
  overflowY: 'auto',
  padding: '20px',
  '&::-webkit-scrollbar': {
    width: '6px'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#e0e0e0',
    borderRadius: '3px'
  }
});

const Message = styled(Paper)(({ isUser }: { isUser: boolean }) => ({
  padding: '10px 16px',
  borderRadius: isUser ? '16px 16px 0 16px' : '0 16px 16px 16px',
  backgroundColor: isUser ? '#f5f5f5' : '#DEF1FF',
  color: isUser ? '#000' : '#000',
  marginBottom: '12px',
  position: 'relative',
  wordBreak: 'keep-all',
  wordWrap: 'break-word',
  transition: 'all 0.3s ease',
  '& a': {
    color: '#11364d',
    fontWeight: 700,
    textDecoration: 'none',
    wordBreak: 'break-all',
    '&:hover': {
      color: '#254B65',
      textDecoration: 'underline'
    }
  },
  '& p': {
    margin: 0,
    '&last-child': {
      margin: '0.5em' // hack because not sure what the MarkDown parser will return in terms of multiple <p> tags
    }
  }
}));

const InputContainer = styled(Box)({
  padding: '16px',
  borderTop: '1px solid #eee',
  display: 'flex',
  alignItems: 'center',
  gap: '8px'
});

const ChatUI = forwardRef(() => {
  const { authState } = useOktaAuth();
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;

  const {
    handleMessageChunk,
    conversation,
    startNewConversation,
    currentUserMessage,
    setUserMessage,
    startSendingCurrentUserMessage,
    isTyping,
    setIsTyping,
    setFeedback,
    citations
  } = useMessageHander();

  const abortController = new AbortController();

  const messagesEndRef = useRef<HTMLInputElement | null>(null);

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversation?.messages]);

  const handleSend = () => {
    setIsTyping(true);
    startSendingCurrentUserMessage();

    conversationApi({
      token: authState?.accessToken?.accessToken ?? '',
      memberid: activeMemberId ?? '',
      payload: conversation,
      abortSignal: abortController.signal,
      onmessage: handleMessageChunk,
      onopen: async (response) => {
        setIsTyping(true);
      },
      onclose: () => {
        setIsTyping(false);
      },
      onerror: (event) => {
        setIsTyping(false);
      }
    });
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const [feedbackAnchorEl, setFeedbackAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const sendFeedback = (messageId: string, feedback: Feedback) => {
    feedbackApi({
      token: authState?.accessToken?.accessToken ?? '',
      memberid: activeMemberId ?? '',
      messageId: messageId,
      feedback: feedback
    }).then(() => {
      setFeedback(messageId, feedback);
      handleFeedbackClose();
    });
  };
  const handleFeedbackClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setFeedbackAnchorEl(e.currentTarget);
  };
  const handleFeedbackClose = () => {
    setFeedbackAnchorEl(null);
  };

  const messagesToDisplay = conversation?.messages.filter((mesasge) => mesasge.id) ?? [];

  return (
    <ChatContainer role="region" aria-label="Chat interface">
      <Box
        sx={{
          display: 'flex',
          padding: '16px',
          borderBottom: '1px solid #eee',
          gap: '8px',
          backgroundColor: '#11364d',
          color: '#fff'
        }}
      >
        <Box>
          <Typography variant="body1" color="inherit">
            RACGP CPD Home help
          </Typography>
          <Link to="/chatbot">
            <Typography variant="body2" color="#fff">
              How can we help?
            </Typography>
          </Link>
        </Box>
        <IconButton
          aria-label="close"
          onClick={startNewConversation}
          sx={{ marginLeft: 'auto', minWidth: 'initial', color: 'inherit' }}
        >
          <RestartAltIcon sx={{ color: 'inherit' }} />
        </IconButton>
      </Box>
      <MessageContainer>
        {messagesToDisplay.map((message, index) => {
          let cleanedContent = message.content;
          let isUser = message.role === 'user';

          for (let step = 1; step < 10; step++) {
            const citation = _.nth(citations, step - 1);
            const uri = citation?.uri ?? '#citation-not-found';
            const title = citation?.title ?? 'citation-not-found';
            const regexPattern = new RegExp(`\\[doc${step}\\]`, 'g');
            cleanedContent = cleanedContent.replace(
              regexPattern,
              `<small>(Source: [${title}](${uri} '${title}'))</small>`
            );
          }

          const components = {
            a: ({ ...props }) => (
              <a {...props} target="_blank" rel="noopener noreferrer">
                {props.children}
              </a>
            )
          };

          const negativeFeedbackList = [
            { feedback: Feedback.Inaccurate, title: 'Inaccurate' },
            { feedback: Feedback.Irrelevant, title: 'Irrelevant' },
            { feedback: Feedback.WrongCitation, title: 'Wrong citation' }
          ];

          return (
            <Fade in={true} key={index} timeout={500}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
                {message.role !== 'user' && (
                  <Box
                    sx={{
                      alignSelf: 'stretch',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Avatar sx={{ mr: 1, bgcolor: '#11364d' }}>
                      <SmartToyIcon />
                    </Avatar>
                    <Box
                      sx={{
                        position: 'relative',
                        flexGrow: 1,
                        display: 'flex'
                      }}
                    >
                      <Box
                        sx={{ position: 'sticky', bottom: 0, mt: 'auto', ml: 'auto', py: '20px' }}
                      >
                        <IconButton
                          size="small"
                          color={message.feedback === Feedback.Positive ? 'success' : 'primary'}
                          aria-label="Positive feedback"
                          onClick={() =>
                            sendFeedback(
                              message.id ?? '',
                              message.feedback !== Feedback.Positive
                                ? Feedback.Positive
                                : Feedback.Neutral
                            )
                          }
                          sx={{ marginLeft: 'auto', minWidth: 'initial' }}
                        >
                          <ThumbUpIcon sx={{ fontSize: 22, color: 'inherit' }} />
                        </IconButton>
                        <IconButton
                          size="small"
                          color={
                            !!message.feedback &&
                            negativeFeedbackList.some((item) => item.feedback === message.feedback)
                              ? 'error'
                              : 'primary'
                          }
                          aria-label="Negative feedback"
                          onClick={handleFeedbackClick}
                          sx={{ marginLeft: 'auto', minWidth: 'initial' }}
                        >
                          <ThumbDownIcon sx={{ fontSize: 22, color: 'inherit' }} />
                        </IconButton>
                        <Menu
                          id="negative-feedback-menu"
                          anchorEl={feedbackAnchorEl}
                          keepMounted
                          disableScrollLock={true}
                          open={Boolean(feedbackAnchorEl)}
                          onClose={handleFeedbackClose}
                        >
                          {negativeFeedbackList.map((item) => (
                            <MenuItem
                              onClick={() =>
                                sendFeedback(
                                  message.id ?? '',
                                  message.feedback !== item.feedback
                                    ? item.feedback
                                    : Feedback.Neutral
                                )
                              }
                              key={item.title}
                              value={item.title}
                              sx={{ fontWeight: message.feedback === item.feedback ? '700' : null }}
                            >
                              {item.title}
                            </MenuItem>
                          ))}
                        </Menu>
                      </Box>
                    </Box>
                  </Box>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    maxWidth: 'calc(100% - 2.25rem)',
                    marginLeft: isUser ? 'auto' : '0',
                    marginRight: isUser ? '0' : 'auto'
                  }}
                >
                  <Box sx={{ width: '100%' }}>
                    <Message isUser={message.role === 'user'}>
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[[rehypeRaw]]}
                        components={components}
                        // className={styles.citationPanelContent}
                        children={DOMPurify.sanitize(cleanedContent, {
                          ALLOWED_TAGS: XSSAllowTags
                        })}
                      />
                    </Message>
                    {message.role !== 'user' && (
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: 'inherit',
                          fontSize: '0.8em',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <AssistantIcon fontSize={'inherit'} sx={{ marginRight: '0.25em' }} />
                        Generated by AI
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Fade>
          );
        })}
        {isTyping && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ml: 5 }}>
            <CircularProgress size={20} />
            <Typography variant="caption">Bot is thinking...</Typography>
          </Box>
        )}
        <div ref={messagesEndRef} />
      </MessageContainer>
      <InputContainer>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type your message..."
          autoFocus={true}
          inputProps={{
            autoFocus: true,
            onKeyPress: (event) => event.key === 'Enter' && handleSend()
          }}
          value={currentUserMessage}
          onChange={(e) => setUserMessage(e.target.value)}
          onKeyDown={handleKeyPress}
          size="small"
          aria-label="Message input"
          multiline
          maxRows={3}
        />
        <IconButton
          color="primary"
          onClick={handleSend}
          disabled={!currentUserMessage.trim()}
          aria-label="Send message"
          sx={{
            '&:hover': {},
            '&.Mui-disabled': {
              backgroundColor: '#e0e0e0',
              color: '#9e9e9e'
            }
          }}
        >
          <SendIcon />
        </IconButton>
      </InputContainer>
    </ChatContainer>
  );
});

export default ChatUI;
