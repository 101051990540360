import { Container, Typography } from '@mui/material';

import { ContentPageTitle } from 'src/components/ContentPageTitle/ContentPageTitle';
import ChatBotPopup from './ChatBotPopup';
import ChatUI from './ChatUI';
import { PermissionDeniedPage } from 'src/components/Error/PermissionDeniedPage';

const ChatBot = () => {
  const hideChatBot = import.meta.env.VITE_CHATBOT_ENABLED.toLowerCase() !== 'true';
  if (hideChatBot) {
    return <PermissionDeniedPage />;
  }

  return (
    <>
      <ContentPageTitle currentPageTitle="ChatBot" />

      <Container maxWidth="lg">
        <Typography color="primary.main" variant="h2" sx={{ mt: 3 }}>
          Need CPD assistance?
        </Typography>
        <ChatUI />
      </Container>
    </>
  );
};
export { ChatBot };
