import { useState } from 'react';
import { Box, Grow, Button } from '@mui/material';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import CloseIcon from '@mui/icons-material/Close';

import ChatUI from './ChatUI';

export const ChatBotPopup = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpen((open) => !open);
  };

  const hideChatBot = import.meta.env.VITE_CHATBOT_ENABLED.toLowerCase() !== 'true';
  if (hideChatBot) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '30px',
        right: '5px',
        zIndex: 1100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end'
      }}
    >
      <Grow
        in={isOpen}
        unmountOnExit
        style={{ transformOrigin: 'bottom right 0' }}
        {...(isOpen ? { timeout: 200 } : {})}
      >
        <Box
          sx={{
            width: '400px',
            maxWidth: '100vw',
            minWidth: 200
          }}
        >
          <ChatUI />
        </Box>
      </Grow>

      <Button
        color="secondary"
        variant="contained"
        size="large"
        onClick={handleClick}
        sx={{ minWidth: 'auto' }}
      >
        <>
          {isOpen && (
            <Grow
              in={isOpen}
              unmountOnExit
              style={{ transformOrigin: 'bottom right 0' }}
              {...(isOpen ? { timeout: 500 } : {})}
            >
              <CloseIcon />
            </Grow>
          )}
          {!isOpen && (
            <Grow
              in={!isOpen}
              unmountOnExit
              style={{ transformOrigin: 'bottom right 0' }}
              {...(!isOpen ? { timeout: 500 } : {})}
            >
              <MarkUnreadChatAltIcon />
            </Grow>
          )}
        </>
      </Button>
    </Box>
  );
};

export default ChatBotPopup;
