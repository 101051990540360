export type Conversation = {
  conversationId: string | null;
  title: string;
  messages: ConversationMessage[];
};

export type NewConversation = {
  messages: {
    content: string;
    role: 'user';
  }[];
};

export type ConversationMessage = {
  id: string | null;
  date: string;
  content: string; // | [{ type: string; text: string }, { type: string; image_url: { url: string } }];
  role: 'assistant' | 'user';
  context?: Context;

  end_turn?: boolean;
  feedback?: Feedback;
};

export type Context = {
  Intent: string;
  Citations: Citation;
};

export type Citation = {
  title: string;
  uri: string;
};

export type UserMessageWithId = {
  id: string | null; // null messsage indicates unsent status
  content: string;
  role: 'user';
};

export type DataChunk = {
  id: string;
  conversationId: string;
  title: string; // | [{ type: string; text: string }, { type: string; image_url: { url: string } }];
  date: string;
  choices: Choice[];
  // User response fields
  role: string;
  content: string;
  feedback?: Feedback;
};

export type Choice = {
  delta: {
    // chat choice fields
    content: string;
    // citation choice fields:
    role: string;
    context: {
      intent: string;
      citations: Citation[];
    };
  };
  end_turn: false;
  finish_reason: null;
};

/// auto generated Types from MS example solution

export type ChatMessage = {
  id: string;
  role: string;
  content: string | [{ type: string; text: string }, { type: string; image_url: { url: string } }];
  end_turn?: boolean;
  date: string;
  feedback?: Feedback;
  context?: string;
};

export type ChatResponse = {
  id: string;
  model: string;
  created: number;
  // object: ChatCompletionType;
  // choices: ChatResponseChoice[];
  history_metadata: {
    conversation_id: string;
    title: string;
    date: string;
  };
  error?: any;
};

export enum Feedback {
  Positive = 'positive',
  Neutral = 'neutral',
  WrongCitation = 'wrong_citation',
  Inaccurate = 'inaccurate',
  Irrelevant = 'irrelevant'
}
