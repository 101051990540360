import { EventSourceMessage, fetchEventSource } from '@microsoft/fetch-event-source';
import { Conversation, Feedback, NewConversation } from './models';

export const conversationApi = async (options: {
  token: string;
  memberid: string;
  payload: Conversation | NewConversation | null;
  abortSignal: AbortSignal;
  onmessage: (ev: EventSourceMessage) => void;
  onopen?: (response: Response) => Promise<void>;
  onclose?: () => void;
  onerror?: (err: any) => number | null | undefined | void;
}) => {
  await fetchEventSource(`${import.meta.env.VITE_CHATBOT_URI}/conversations/stream`, {
    method: 'POST',
    headers: {
      Accept: 'text/event-stream',
      'Ocp-Apim-Subscription-Key': 'd00dc7209d1e430bad35849a0f4cdb69',
      'Content-Type': 'application/json',
      Connection: 'keep-alive',
      Authorization: `Bearer ${options.token}`,
      memberid: options.memberid
    },
    body: JSON.stringify(options.payload), // Your POST data
    signal: options.abortSignal,
    async onopen(response) {
      if (response.ok && response.status === 200) {
        console.log('Connection made');
      } else if (response.status >= 400 && response.status < 500) {
        throw new Error('Client side error');
      } else if (response.status >= 500 && response.status < 600) {
        throw new Error('Server side error');
      }
      if (options.onopen) options.onopen(response);
    },
    onmessage(event) {
      try {
        options.onmessage(event);
      } catch (error) {
        console.error('Error parsing SSE data:', error);
        if (options.onerror) options.onerror(error);
      }
    },
    onerror(error) {
      console.error('SSE connection error:', error);
      if (options.onerror) options.onerror(error);
    },
    onclose() {
      console.log('SSE connection closed');
      if (options.onclose) {
        options.onclose();
      }
    }
  });
};

export const feedbackApi = async (options: {
  token: string;
  memberid: string;
  messageId: string;
  feedback: Feedback;
}) => {
  try {
    let url = new URL(`${import.meta.env.VITE_CHATBOT_URI}/conversations/messages/feedback`);
    url.searchParams.append('messageId', options.messageId);
    url.searchParams.append('feedback', options.feedback);
    await fetch(url, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        // 'Ocp-Apim-Subscription-Key': 'd00dc7209d1e430bad35849a0f4cdb69',
        // 'Content-Type': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        Connection: 'keep-alive',
        Authorization: `Bearer ${options.token}`,
        memberid: options.memberid
      }
    });
  } catch (ex) {
    console.error('ex:', ex);
  }
};
