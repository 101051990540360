import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Container,
  Divider,
  Paper,
  TextField,
  Typography,
  Snackbar,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Link
} from '@mui/material';
import { endOfDay, format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';

import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { useDeleteQuickLogActivityMutation } from 'src/graphql/generated';
import { useErrorHandler } from 'src/hooks/useErrorHandler';
import { colors } from 'src/components/App/colors';
import { SaveQuickLogActivityMutation, useGetReferencesQuery } from 'src/graphql/generated';
import { Attachments } from 'src/components/Attachments/Attachments';
import { QuickLogFormData } from './types';
import { useSubmitQuickLog } from './useSubmitQuickLog';
import { CPD_TOTAL_HOUR_LIMIT } from 'src/components/Attachments/constants';
import { clamp } from 'lodash';
import { CPDTypeInputSection } from './CPDInputSection';
import { Section } from './Section';
import { Button } from 'src/components/Button/Button';
import { ContentPageTitle } from '../../ContentPageTitle/ContentPageTitle';
import { LoadableBox } from '../../Loadable/LoadableBox';
import { AttachmentsArray } from './types';
import { Alerts } from './Alerts';
import { useMemberActivity } from './useMemberActivity';
import { nanToZero, roundHalf } from 'src/components/Attachments/utils';
import { ConfirmDelete } from './ConfirmDelete';
import sortBy from 'lodash/sortBy';
import { InlineBox } from '../../Layout/InlineBox';
import { RolesRestricted } from 'src/components/RequiredAuth/RolesAuthRoute';
import { Tooltip } from './Tooltip';

type LogProps = {
  id?: string;
  cpdId?: string;
  urlFormData?: QuickLogFormData;
};

const Log = ({ id, cpdId, urlFormData }: LogProps) => {
  const earef = useRef<HTMLInputElement>();
  const rpref = useRef<HTMLInputElement>();
  const moref = useRef<HTMLInputElement>();

  const editing = !!id || !!cpdId;

  const { activeMemberId } = React.useContext(AuthContext) as AuthContextType;
  const history = useHistory();

  let maxDate = endOfDay(new Date());

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);

  const handleError = useErrorHandler();

  const [deleteQuickLogActivityMutation, { error: deleteError }] =
    useDeleteQuickLogActivityMutation();

  const { data: referenceData } = useGetReferencesQuery({ onError: handleError });

  const scrollRef = useRef<HTMLElement>(null);

  const { register, handleSubmit, control, watch, formState, setValue, reset } =
    useForm<QuickLogFormData>({
      defaultValues: {
        activitykey: null,
        activity_title: '',
        activity_date: null,
        ea_hours: 0,
        rp_hours: 0,
        mo_hours: 0,
        total_hours: 0,
        program_levels: [],
        notes: '',
        attachments: []
      },
      mode: 'onChange'
    });

  const displayPlrQuestion = (watch('activity_date') ?? new Date()) >= new Date('2024-01-01');

  const { memberActivityData, error: activityError } = useMemberActivity({
    activityKey: id,
    cpdId: cpdId,
    memberId: activeMemberId
  });

  const scrollToTop = useCallback(() => {
    setTimeout(() => {
      if (scrollRef.current) {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }, 10);
  }, []);

  const handlePrlChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const valueCopy = [...watch().program_levels];
    const clickedValue = event.target.value;
    if (checked) {
      valueCopy.push(clickedValue); // append to array
    } else {
      const idx = valueCopy.findIndex((selectedPlr) => selectedPlr === clickedValue);
      valueCopy.splice(idx, 1); // remove from array
    }

    setValue('program_levels', valueCopy, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  const loading = editing && !memberActivityData && !activityError;
  const activityPending =
    editing && memberActivityData && !memberActivityData.getMemberActivityByKey?.cpd_id;

  const formDisabled = activityPending || !!activityError;

  const copyLogURL = () => {
    setSnackBarOpen(true);
    const url = new URL(`${window.location.origin}/log`);
    url.searchParams.set('logName', watch('activity_title'));
    if (watch('ea_hours') > 0) {
      url.searchParams.set('ea_hours', watch('ea_hours').toString());
    }
    if (watch('rp_hours') > 0) {
      url.searchParams.set('rp_hours', watch('rp_hours').toString());
    }
    if (watch('mo_hours') > 0) {
      url.searchParams.set('mo_hours', watch('mo_hours').toString());
    }
    const date = watch('activity_date');
    if (date) {
      url.searchParams.set('date', format(date, 'yyyy-MMM-dd HH:mm:ss'));
    }
    const program_levels = watch('program_levels');
    if (program_levels.length > 0) {
      url.searchParams.set('program_levels', program_levels.join('|'));
    }
    if (watch('notes')) {
      url.searchParams.set('notes', watch('notes'));
    }
    navigator.clipboard.writeText(url.toString());
  };
  const handleSnackBarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarOpen(false);
  };

  const deleteQuickLog = () => {
    deleteQuickLogActivityMutation({
      variables: {
        memberid: activeMemberId,
        activitykey: id
      },
      onCompleted: (data) => {
        if (data.deleteQuickLogActivity?.activitykey) {
          history.push('/history', { replace: true, state: { deleteId: id } });
        }
      },
      onError: () => {
        scrollToTop();
      }
    });
  };

  const onPressDelete = () => {
    setDeleteDialogOpen(true);
  };

  const onConfirmDelete = () => {
    setDeleteDialogOpen(false);
    deleteQuickLog();
  };

  useEffect(() => {
    //reset form to member activity data when it's available, so isDirty will be false after reset
    if (editing && memberActivityData?.getMemberActivityByKey) {
      const {
        activitykey,
        title,
        date,
        cpd_types,
        hours,
        description,
        attachments,
        program_levels
      } = memberActivityData?.getMemberActivityByKey;
      reset({
        activitykey: activitykey,
        activity_title: title || '',
        activity_date: date ? new Date(date) : null,
        ea_hours: cpd_types?.find((cpdType) => cpdType?.type === 'EA')?.hours || 0,
        rp_hours: cpd_types?.find((cpdType) => cpdType?.type === 'RP')?.hours || 0,
        mo_hours: cpd_types?.find((cpdType) => cpdType?.type === 'MO')?.hours || 0,
        total_hours: hours,
        program_levels: (program_levels as string[]) || [],
        notes: description || '',
        attachments: (attachments as AttachmentsArray) || []
      });
    }
  }, [editing, memberActivityData, reset]);

  const {
    submit,
    data: submissionResponse,
    loading: submitting,
    error: submissionError,
    reset: resetSubmissionMutation
  } = useSubmitQuickLog({
    onCompleted: (data: SaveQuickLogActivityMutation) => {
      if (editing) {
        history.push('/history', {
          updatedId: data.saveQuickLogActivity?.activitykey
        });
      } else {
        reset();
        scrollToTop();
      }
    },
    onError: (e: any) => {
      scrollToTop();
      console.error('submit failed');
      console.error(e);
    }
  });

  const submitForm = handleSubmit((data) => {
    submit(data);
  });

  const activityTitleOptions = useMemo(() => {
    if (!referenceData?.activity_title?.items) {
      return undefined;
    }
    return sortBy(referenceData.activity_title.items, (item) => item?.value).map((i) => ({
      label: i?.value,
      key: i?.key
    }));
  }, [referenceData]);

  const updateTotalHours = useCallback(() => {
    setValue('total_hours', watch('mo_hours') + watch('rp_hours') + watch('ea_hours'), {
      shouldValidate: true,
      shouldDirty: true
    });
  }, [setValue, watch]);

  const roundField = useCallback(
    (fieldName: 'ea_hours' | 'rp_hours' | 'mo_hours') => {
      setValue(fieldName, clamp(roundHalf(watch(fieldName)), 0, 500), {
        shouldDirty: true,
        shouldValidate: true
      });
      updateTotalHours();
    },
    [setValue, updateTotalHours, watch]
  );

  const setTitle = (title: string) => {
    setValue('activity_title', title || '', {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  // Effect to load URL data
  useEffect(() => {
    if (urlFormData) {
      // activity date is not validating after using reset - do this manually
      setValue('activity_date', urlFormData.activity_date, { shouldValidate: true });
      // reset the form to use the URL form data
      reset(urlFormData, { keepDefaultValues: true });
      // reset does not trigger re-calculation of totalhours so do it manually
      const newTotalHours = urlFormData.mo_hours + urlFormData.rp_hours + urlFormData.ea_hours;
      setValue('total_hours', newTotalHours);
    }
  }, [urlFormData, reset, setValue]);

  // this needs to be destructured like this, writing formState.isValid will cause the value to be ignored on first render for some reason
  const { isValid, isDirty } = formState;

  return (
    <>
      <Box ref={scrollRef}>
        <ContentPageTitle currentPageTitle="Quick Log" />
      </Box>
      <form>
        <Container
          maxWidth={'lg'}
          sx={{
            textAlign: 'start',
            mt: 3
          }}
        >
          <Alerts
            activityPending={activityPending}
            onDismissSuccessfulSubmission={resetSubmissionMutation}
            submissionError={submissionError}
            submissionResponse={submissionResponse}
            activityError={activityError}
            deleteError={deleteError}
          />

          <ConfirmDelete
            onClose={() => setDeleteDialogOpen(false)}
            onConfirm={onConfirmDelete}
            isOpen={deleteDialogOpen}
          />

          <Paper elevation={3} sx={{ p: { xs: 2, md: 3 }, pb: 4 }}>
            <Typography sx={{ color: colors['charcoal.700'] }} variant={'body2'}>
              Easily log your professional development activities e.g. reading medical journals,
              participation in practice meetings, listening to medical or other relevant podcasts.
              You can also log medico-legal work, committee work, participation in clinical
              governance meetings, and any other activities related to your scope of practice.
            </Typography>
            <Alert sx={{ my: 1.5 }} severity={'info'}>
              <AlertTitle>
                <Typography variant={'body2'} component={'div'}>
                  External Provider activities will be recorded on your behalf. Please allow up to{' '}
                  <InlineBox fontWeight={600}>30 days</InlineBox> for it to appear in your History.
                </Typography>
              </AlertTitle>
            </Alert>
            <Divider sx={{ color: colors['stone.100'], mb: 3 }} />
            <Section title={'Activity Details'} required>
              <Controller
                control={control}
                name={'activity_title'}
                rules={{ required: true }}
                render={({ formState, fieldState, field: { value, name, onChange, ref } }) => {
                  return (
                    <LoadableBox sx={{ width: '100%', mb: 4 }} loading={loading}>
                      <Autocomplete
                        ref={ref}
                        disablePortal
                        disabled={formDisabled}
                        getOptionLabel={(o) => (typeof o === 'string' ? o : o.label || '')}
                        id={name}
                        freeSolo
                        options={activityTitleOptions || []}
                        onInputChange={(e: any, value, reason) => {
                          if (reason === 'input') {
                            setTitle(value);
                          }
                        }}
                        onChange={(e, value: any, reason) => {
                          setTitle(value?.label);
                        }}
                        value={value}
                        renderInput={(params) => (
                          <TextField
                            value={value}
                            ref={params.InputProps.ref}
                            label="Activity title *"
                            {...params}
                          />
                        )}
                      />
                    </LoadableBox>
                  );
                }}
              />

              <Controller
                control={control}
                name={'activity_date'}
                rules={{
                  required: true,
                  validate: {
                    required: (value) => !!value,
                    triennium2023OrLater: (value) => {
                      if (!value) return;
                      const isTriennium2023OrLater =
                        new Date(value) >= new Date('2022-12-31T13:00:00.000Z');
                      if (!isTriennium2023OrLater) {
                        return 'Activity date must be on or after 02/01/2023.';
                      }
                    },
                    isPast: (value) => {
                      if (!value) return;
                      const isPast = new Date(value) <= maxDate;
                      if (!isPast) {
                        return `Activity date must be on or before ${format(
                          new Date(),
                          'dd/MM/yyyy'
                        )}.`;
                      }
                    }
                  }
                }}
                render={({
                  formState,
                  fieldState: { error },
                  field: { value, name, onChange, ref }
                }) => (
                  <LoadableBox loading={loading}>
                    <DatePicker
                      openTo="year"
                      ref={ref}
                      inputFormat={'dd/MM/yyyy'}
                      views={['day']}
                      value={value || null}
                      onChange={(e) => {
                        setValue('activity_date', e, {
                          shouldValidate: true,
                          shouldDirty: true
                        });
                      }}
                      disabled={formDisabled}
                      minDate={new Date('01/02/2023')}
                      maxDate={maxDate}
                      renderInput={(params) => (
                        <TextField
                          sx={{ width: { xs: '100%', sm: '50%' } }}
                          {...params}
                          label="Activity completion date *"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </LoadableBox>
                )}
              />

              <Typography color={'primary'} variant={'body1'} mt={4} mb={0.5}>
                Type of CPD*
              </Typography>
              <Typography sx={{ color: colors['charcoal.700'] }} variant={'body2'}>
                Log hours against any CPD types that apply to your activity. See our CPD activity
                types guide{' '}
                <Link
                  href="https://www1.racgp.org.au/getmedia/31e42c2d-7f6c-42c3-8b74-80ba76d88d9e/CPD-activity-types-guide.pdf.aspx"
                  target="_blank"
                >
                  here
                </Link>
                . Hours will be totalled in increments of 30 minutes (0.5).
              </Typography>

              <Box sx={{ width: { xs: '100%', sm: '50%' } }}>
                <CPDTypeInputSection
                  title={'Educational Activities'}
                  loading={loading}
                  tooltip={
                    'Educational Activities (EA) are activities that expand your general practice knowledge, skills, and attitudes, related to your scope of practice'
                  }
                >
                  <TextField
                    {...register('ea_hours', {
                      setValueAs: (v) => nanToZero(Number.parseFloat(v)),
                      onBlur: () => {
                        roundField('ea_hours');
                      }
                    })}
                    sx={{ width: '100%' }}
                    type={'number'}
                    disabled={formDisabled}
                    inputProps={{ min: 0, step: 0.5, max: 500 }}
                    InputLabelProps={{ shrink: true }}
                    label={'Hours'}
                    defaultValue={0}
                    inputRef={earef}
                    onClick={() => earef.current?.focus()}
                  ></TextField>
                </CPDTypeInputSection>

                <CPDTypeInputSection
                  title={'Reviewing Performance'}
                  loading={loading}
                  tooltip={
                    'Reviewing Performance (RP) are activities that require reflection on feedback about your work'
                  }
                >
                  <TextField
                    {...register('rp_hours', {
                      setValueAs: (v) => nanToZero(Number.parseFloat(v)),
                      onBlur: () => {
                        roundField('rp_hours');
                      }
                    })}
                    sx={{ width: '100%' }}
                    type={'number'}
                    disabled={formDisabled}
                    inputProps={{ min: 0, step: 0.5 }}
                    InputLabelProps={{ shrink: true }}
                    label={'Hours'}
                    inputRef={rpref}
                    onClick={() => rpref.current?.focus()}
                  ></TextField>
                </CPDTypeInputSection>

                <CPDTypeInputSection
                  title={'Measuring Outcomes'}
                  loading={loading}
                  tooltip={
                    'Measuring Outcomes (MO) are activities that use your work data to ensure quality results'
                  }
                >
                  <TextField
                    {...register('mo_hours', {
                      setValueAs: (v) => nanToZero(Number.parseFloat(v)),
                      onBlur: () => {
                        roundField('mo_hours');
                      }
                    })}
                    sx={{ width: '100%' }}
                    type={'number'}
                    disabled={formDisabled}
                    inputProps={{ min: 0, step: 0.5 }}
                    InputLabelProps={{ shrink: true }}
                    label={'Hours'}
                    inputRef={moref}
                    onClick={() => moref.current?.focus()}
                  ></TextField>
                </CPDTypeInputSection>

                <Divider sx={{ my: 2 }} />

                <Controller
                  control={control}
                  name={'total_hours'}
                  rules={{
                    min: { value: 0.5, message: '' },
                    max: {
                      value: CPD_TOTAL_HOUR_LIMIT,
                      message: `${CPD_TOTAL_HOUR_LIMIT} CPD hour limit per Quick Log`
                    },
                    required: true
                  }}
                  render={({ formState, fieldState, field: { value, name, onChange } }) => {
                    const showError = !!fieldState.error?.message;

                    return (
                      <Box>
                        <Box display={'flex'} justifyContent={'space-between'}>
                          <Typography
                            variant={'body1'}
                            fontWeight={'bold'}
                            sx={{ color: colors['charcoal.900'] }}
                          >
                            Total:
                          </Typography>
                          <Typography
                            variant={'body1'}
                            fontWeight={'bold'}
                            sx={{
                              color: showError ? colors['red.700'] : colors['darkTeal.700']
                            }}
                          >
                            {value}
                            {' CPD hours'}
                          </Typography>
                        </Box>
                        {showError && (
                          <Box display={'flex'} justifyContent={'flex-end'}>
                            <Typography
                              variant={'caption'}
                              color={colors['red.700']}
                              alignSelf={'flex-end'}
                            >
                              {fieldState.error?.message}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    );
                  }}
                />
              </Box>
            </Section>
            {displayPlrQuestion && (
              <>
                <Divider sx={{ my: 4 }} />

                <Section title={'Program-Level Requirements'} required={false}>
                  <Typography sx={{ color: colors['charcoal.700'], mb: 2 }} variant={'body2'}>
                    To meet the MBA CPD registration standard, select the program-level
                    requirement/s relevant to this activity, where&nbsp;applicable.
                  </Typography>
                  <LoadableBox sx={{ mb: 2 }} loading={loading}>
                    <FormGroup sx={{ alignItems: 'flex-start' }}>
                      <Box display="flex" alignItems="center" gap={1}>
                        <FormControlLabel
                          sx={{ mr: 0 }}
                          control={
                            <Checkbox
                              value="CSP"
                              checked={watch().program_levels.some(
                                (selectedPlr) => selectedPlr === 'CSP'
                              )}
                              onChange={handlePrlChange}
                            />
                          }
                          label="Culturally Safe Practice"
                        />
                        <Tooltip tooltip="Cultural safety is determined by Aboriginal and Torres Strait Islander individuals, families and communities" />
                      </Box>
                      <Box display="flex" alignItems="center" gap={1}>
                        <FormControlLabel
                          sx={{ mr: 0 }}
                          control={
                            <Checkbox
                              value="HI"
                              checked={watch().program_levels.some(
                                (selectedPlr) => selectedPlr === 'HI'
                              )}
                              onChange={handlePrlChange}
                            />
                          }
                          label="Health Inequities"
                        />
                        <Tooltip tooltip="Health inequities are systematic differences in the opportunities groups have to achieve optimal health, leading to unfair avoidable differences in health outcomes" />
                      </Box>

                      <Box display="flex" alignItems="center" gap={1}>
                        <FormControlLabel
                          sx={{ mr: 0 }}
                          control={
                            <Checkbox
                              value="EP"
                              checked={watch().program_levels.some(
                                (selectedPlr) => selectedPlr === 'EP'
                              )}
                              onChange={handlePrlChange}
                            />
                          }
                          label="Professionalism and Ethical Practice"
                        />
                        <Tooltip tooltip="Doctors have a duty to make the care of patients their first concern and practice medicine safely and effectively" />
                      </Box>
                    </FormGroup>
                  </LoadableBox>
                </Section>
              </>
            )}
            <Divider sx={{ my: 4 }} />
            <Box
              sx={[
                isDirty &&
                  editing &&
                  watch().notes.length > 1000 && {
                    'p, span': {
                      color: 'red'
                    }
                  }
              ]}
            >
              <Section title={'Reflection'} required={false}>
                <Typography
                  sx={{ color: colors['charcoal.700'] + '!important', mb: 2 }}
                  variant={'body2'}
                >
                  For completeness of records, please ensure you add a reflection and/or upload
                  evidence to support your CPD activity type/s and hours claimed.
                  <br />
                  <br />
                  What did you learn? What changes would you make to your practise as a result?
                </Typography>
                <LoadableBox sx={{ mb: 2 }} loading={loading}>
                  <Controller
                    control={control}
                    name={'notes'}
                    rules={{
                      maxLength: 1000
                    }}
                    render={({ fieldState: { error }, field: { value } }) => (
                      <TextField
                        {...register('notes')}
                        value={value}
                        fullWidth
                        label="Notes"
                        disabled={formDisabled}
                        multiline
                        inputProps={{ maxLength: 1000 }}
                        minRows={5}
                        maxRows={15}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </LoadableBox>
                <Typography component="p" variant="caption" align="right" sx={{ mt: 1 }}>
                  {watch().notes.length}/1000
                </Typography>
              </Section>
            </Box>
            <Divider sx={{ my: 4 }} />
            <Section title={'Evidence'} required={false}>
              <Typography
                sx={{ color: colors['charcoal.700'], mb: 2, a: { textDecoration: 'underline' } }}
                variant={'body2'}
              >
                You do not need to provide any further evidence in this application but ensure you
                keep adequate records of your activity/ies as you may be audited by the RACGP or
                Medical Board of Australia (MBA). The MBA requires evidence of your annual CPD
                activities to be retained for three years. See evidence guide{' '}
                <a
                  href="https://www.racgp.org.au/getmedia/726d015d-c7ed-488d-94bf-e708559f3f75/CPD-Activities-Evidence-Guide.pdf.aspx"
                  target="_blank"
                  rel="noreferrer"
                >
                  here.
                </a>
              </Typography>

              {!formDisabled && (
                <Controller
                  control={control}
                  name={'attachments'}
                  rules={{ max: 5 }}
                  render={({ formState, fieldState, field: { value, name, onChange } }) => {
                    return (
                      <LoadableBox loading={loading}>
                        <Attachments
                          onChangeAttachments={onChange}
                          attachments={value}
                          isEditing={editing}
                          uploadText="EVIDENCE"
                        />
                      </LoadableBox>
                    );
                  }}
                />
              )}
            </Section>
            <Divider sx={{ my: 4 }} />
            <Box display={'flex'} gap={2} justifyContent={{ xs: 'center', md: 'flex-end' }}>
              <RolesRestricted roles={['staff']} hideChildComponent={true}>
                <Button
                  sx={{ width: '100%', maxWidth: 180, height: 40, marginRight: 'auto' }}
                  color="primary"
                  variant={editing ? 'contained' : 'outlined'}
                  disabled={submitting || formDisabled}
                  onClick={copyLogURL}
                >
                  Share Log URL
                </Button>
                <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleSnackBarClose}>
                  <Alert onClose={handleSnackBarClose} severity="success" sx={{ width: '100%' }}>
                    Log URL copied
                  </Alert>
                </Snackbar>
              </RolesRestricted>
              <Button
                sx={{ width: '100%', maxWidth: 180, height: 40 }}
                color="primary"
                variant={editing ? 'contained' : 'outlined'}
                disabled={submitting || formDisabled}
                onClick={() => {
                  editing ? onPressDelete() : reset();
                }}
              >
                {editing ? 'Delete' : 'Clear'}
              </Button>
              <Button
                sx={{ width: '100%', maxWidth: 180, height: 40 }}
                color="primary"
                variant="contained"
                type={'submit'}
                disabled={!isValid || !isDirty || formDisabled}
                onClick={submitForm}
                loading={submitting}
              >
                {editing ? 'Update' : 'Submit'}
              </Button>
            </Box>
          </Paper>
        </Container>
      </form>
    </>
  );
};

export { Log };
