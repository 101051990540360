import { useState } from 'react';
import { EventSourceMessage } from '@microsoft/fetch-event-source';

import { Citation, Conversation, DataChunk, Feedback } from './models';
import _ from 'lodash';

export const useMessageHander = () => {
  const [conversation, setConversation] = useState<Conversation | null>(null);

  const currentUserMessage = conversation?.messages?.find((message) => !message.id)?.content ?? '';

  const startSendingCurrentUserMessage = () => {
    setConversation((prevConversation) => {
      let nextConversation = prevConversation ? _.cloneDeep(prevConversation) : null;
      if (nextConversation === null) {
        throw Error('Cannot send message before starting Conversation');
      }

      let messageToSend = nextConversation.messages.find((message) => !message.id);
      if (messageToSend) {
        messageToSend.id = 'SENDING';
      }
      return nextConversation;
    });
  };

  const setUserMessage = (userMessage: string, sending: boolean = false) => {
    setConversation((prevConversation) => {
      let nextConversation = prevConversation ? _.cloneDeep(prevConversation) : null;
      if (nextConversation === null) {
        nextConversation = {
          conversationId: null, // set as null by default
          title: 'TEMP',
          messages: []
        };
      }

      let messageToUpdate = nextConversation.messages.find((message) => !message.id);
      if (messageToUpdate) {
        messageToUpdate.content = userMessage;
        messageToUpdate.id = sending ? 'SENDING' : null;
      } else {
        nextConversation.messages.push({
          id: null,
          role: 'user',
          content: userMessage,
          date: new Date().toISOString()
        });
        // throw Error('Cannot update user message');
      }
      return nextConversation;
    });
  };

  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [citations, setCitations] = useState<Citation[]>([]);

  const startNewConversation = () => {
    setConversation(null);
    setCitations([]);
    setIsTyping(false);
  };

  const handleContentDataChunk = (chunk: DataChunk) => {
    setConversation((prevConversation) => {
      let nextConversation = prevConversation ? _.cloneDeep(prevConversation) : null;
      if (!nextConversation?.conversationId) {
        nextConversation = {
          conversationId: chunk.conversationId,
          title: chunk.title,
          messages: nextConversation?.messages ?? []
        };
      }
      let currentAssistantMessage = nextConversation?.messages?.find(
        (message) => message.id === chunk.id
      );
      const nextChoice = (chunk?.choices[0] ?? [])?.delta?.content || '';
      if (currentAssistantMessage) {
        currentAssistantMessage.content += nextChoice;
      } else {
        nextConversation?.messages.push({
          role: 'assistant',
          id: chunk.id,
          date: chunk.date,
          content: nextChoice,
          feedback: chunk.feedback
        });
      }

      return nextConversation;
    });
  };

  const handleUserDataChunk = (chunk: DataChunk) => {
    setConversation((prevConversation) => {
      let nextConversation = prevConversation ? _.cloneDeep(prevConversation) : null;
      if (nextConversation === null) {
        nextConversation = {
          conversationId: chunk.conversationId,
          title: chunk.title,
          messages: []
        };
      }

      let messageToUpdate = nextConversation?.messages?.find((message) => message.id === 'SENDING');
      if (messageToUpdate) {
        messageToUpdate.id = chunk.id;
      } else {
        nextConversation.messages.push({
          id: chunk.id,
          role: 'user',
          content: chunk.content,
          date: ''
        });
        // throw Error('Cannot update user message');
      }

      return nextConversation;
    });
  };

  const setFeedback = (messageId: string, feedback: Feedback) => {
    setConversation((prevConversation) => {
      let nextConversation = prevConversation ? _.cloneDeep(prevConversation) : null;
      if (nextConversation === null) {
        throw Error('Cannot record feedback message before starting Conversation');
      }

      let messageToUpdate = nextConversation?.messages?.find((message) => message.id === messageId);
      if (messageToUpdate) {
        messageToUpdate.feedback = feedback;
      } else {
        throw Error('Cannot find message to record feedback');
      }

      return nextConversation;
    });
  };

  const handleCitationDataChunk = (chunk: DataChunk) => {
    var citations = chunk.choices[0]?.delta?.context.citations || [];
    if (citations?.length > 0) {
      const mappedCitations = citations.map((citation: Citation) => ({
        ...citation
      }));
      setCitations(mappedCitations);
    }
  };

  const handleMessageChunk = (event: EventSourceMessage) => {
    try {
      // deal with Error Event
      // if (event.error) {
      //   throw Error(result.error);
      // }

      // deal with data Event
      const data = event.data;

      if (data) {
        // deal with end of steam
        if (data.startsWith('[DONE]')) {
          setIsTyping(false);
          console.log('Done!');
          return;
        }

        // otherwise it is a data chunk
        var dataChunk = JSON.parse(data) as DataChunk;
        const choice = _.first(dataChunk?.choices ?? []);
        const hasContent = choice && choice?.delta?.content?.length > 0;
        if (hasContent) {
          handleContentDataChunk(dataChunk);
        }
        const hasCitation = choice && choice?.delta?.context?.citations?.length > 0;
        if (hasCitation) {
          handleCitationDataChunk(dataChunk);
        }
        const isUser = dataChunk?.role === 'user';
        if (isUser) {
          handleUserDataChunk(dataChunk);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsTyping(false);
    }
  };

  return {
    handleMessageChunk,
    conversation,
    currentUserMessage,
    setUserMessage,
    citations,
    isTyping,
    setIsTyping,
    startSendingCurrentUserMessage,
    setFeedback,
    startNewConversation
  };
};
